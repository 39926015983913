import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loader from './layout/Loader'
import { useAuth } from '../customHooks/useAuth';
import ScrollToTop from './layout/ScrollToTop';
import staticRoutes from '../routes/staticRoutes';
import GeneralErrorBoundary from './components/ErrorBoundaries/GeneralErrors';

const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader
});

const ClientLayout = Loadable({
  loader: () => import('./layout/ClientLayout'),
  loading: Loader
});

const InfluencerLayout = Loadable({
  loader: () => import('./layout/InfluencerLayout'),
  loading: Loader
});

const PublicProposalLayout = Loadable({
  loader: () => import('./layout/SalesProposalLayout'),
  loading: Loader
});

const App = () => {
  const { role, sessionToken, getSessionToken } = useAuth();
  let currentLayout;

  if (sessionToken === undefined) {
    getSessionToken();
  } else if (sessionToken === null) {
    if (/^\/p\/.+/.test(window.location.pathname)) {
      currentLayout = PublicProposalLayout
    } else if (/^\/i\/.+/.test(window.location.pathname)) {
      window.location = `${process.env.REACT_APP_SV_AUTH_APP}/influencers/sign_in?return_to=${window.location.href}`
    } else if (/^\/c\/.+/.test(window.location.pathname)) {
      window.location = `${process.env.REACT_APP_SV_AUTH_APP}/brands/sign_in?return_to=${window.location.href}`
    } else {
      window.location = process.env.REACT_APP_SV_AUTH_APP
    }
  } else if (sessionToken) {
    if (role === 'sv_admins') {
      currentLayout = AdminLayout
    } else if (role === 'brands') {
      currentLayout = ClientLayout
    } else if (role === 'influencers') {
      currentLayout = InfluencerLayout
    }
  };

  const menu = staticRoutes.map((route, index) => {
    return (route.component) ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={props => (
          <route.component {...props} />
        )} />
    ) : (null);
  });

  return (
    <GeneralErrorBoundary>
      <ScrollToTop>
        <Suspense fallback={<Loader />}>
          <Switch>
            {menu}
            <Route path='/' component={currentLayout} />
            <Redirect to='error' />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </GeneralErrorBoundary>
  )
};

export default App;
