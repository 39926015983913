import React, { useState, useContext, createContext } from 'react';
import * as userAction from '../helpers/auth';

const initialNullSession = {
  token: undefined,
  expiresIn: 0,
  role: null,
  uid: null,
  isLoggedIn: false
};

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
};

const useAuth = () => {
  return useContext(AuthContext);
};

const useProvideAuth = () => {
  const [userSession, setUserSession] = useState({
    ...initialNullSession
  });

  const {
    token: sessionToken,
    isLoggedIn: isUserLoggedIn,
    role, uid
  } = userSession;

  const setSessionHandler = ({
    token, expiresIn, role, uid, isLoggedIn
  }) => {
    setUserSession({
      token, expiresIn, role, uid, isLoggedIn
    });
  };

  const getSessionToken = () => {
    userAction.userSessionToken()
      .then(({ jwtToken, exp, role, uid }) => {
        setSessionHandler({
          token: jwtToken,
          expiresIn: exp,
          role,
          uid,
          isLoggedIn: true
        })
      }).catch(() => {
        setSessionHandler({
          ...initialNullSession,
          token: null
        });
      });
  };

  const signOut = () => {
    return userAction.userSignOut()
      .then(res => {
        setSessionHandler({
          ...initialNullSession,
          token: null
        });
        return res;
      })
  };

  return {
    sessionToken,
    role, uid,
    isUserLoggedIn,
    getSessionToken,
    signOut
  };
};

export { AuthProvider, useAuth };
