import React from 'react';

export default class GeneralErrorBoundary extends React.Component {
  state = { hasError: false }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });

    // For testing purpose, move to error logger
    console.log('GE error: ', error)
    console.log('GE errorInfo: ', errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <h1>.</h1>
    }

    return this.props.children;
  }
}