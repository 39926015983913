import React from 'react';

const ErrorNotFoundPage = React.lazy(() => import('../App/Pages/Static/Maintenance/ErrorNotFound'));

const CmpReport = React.lazy(() => import('../App/Pages/Demo/CampaignReport'));


const routes = [
  { path: '/error', exact: true, name: 'Page Not Found', component: ErrorNotFoundPage },

  { path: "/c/0bPMoQ/cmp/xxx-kol-activation/report", exact: true, name: "ClientCmpReport", component: CmpReport }
];

export default routes;